import { BrowserRouter, Routes, Route } from "react-router-dom";
import Brands from "./Components/Brands";
import RetailerData from "./Components/RetailerData";
import Search from './Components/Search/index';


export default function App() {
  return (
    <BrowserRouter>
      <Routes>
          <Route index element={<Search />} />
          <Route path="brands" element={<Brands />} />
          <Route path="retailer-data" element={<RetailerData />} />
      </Routes>
    </BrowserRouter>
  );
}