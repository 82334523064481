import React, { useEffect, useState } from 'react'
import './index.css'
import NavBar from '../Common/NavBar'
import Card from '../Common/Card'
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';

function Brands() {

  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const {state} = useLocation();

  useEffect(() => {

    const auth = {
      username: 'Vedant',
      password: 'Vedant123'
    }
     const getBrands = async() => {
      setLoading(true)
      try{
        axios.get(`http://13.232.14.208/api/brand/all`, {auth})
          .then(function (response) {
            // handle success
            setBrands(response.data)
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          })
      }
      catch(err)
      {
        console.log(err.message)
      }
      finally{
        setLoading(false)
      }
    }

    state && getBrands()
  }, [state])

  const handleBrandClick = (brand_name) => {
    navigate('/retailer-data', { state: {brand_name, retailer_code:state} });
  }

  return (
    <>
    <NavBar title={state} />
    <div className="brand-container">
    {
    brands.length > 0 && brands.map((value, i) => (
      <Card key={i} title={value.Brand_Name} handleBrandClick={()=>handleBrandClick(value.Brand_Name)} />
    ))
    }
    </div>
    </>
  )
}

export default Brands