import React, { useEffect, useState } from 'react'
import './index.css'
import logo from '../../images/logo.png'
import axios from 'axios'
import { useNavigate } from 'react-router-dom';

function Search() {

  const [search, setSearch] = useState('');
  const [suggestions, setSuggestions] = useState('');
  const [suggestionsVisible, setSuggestionVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {

    const auth = {
      username: 'Vedant',
      password: 'Vedant123'
    }
     const getSuggestions = async() => {
      setLoading(true)
      try{
        axios.get(`http://13.232.14.208/api/suggest/${search}`, {auth})
          .then(function (response) {
            // handle success
            setSuggestions(response.data)
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          })
      }
      catch(err)
      {
        console.log(err.message)
      }
      finally{
        setLoading(false)
      }
    }

    search.length > 2 && getSuggestions()
  }, [search])

  const handleClick = (value) => {
    setSearch(value)
    setSuggestionVisible(false)
  }

  const handleChange = (e) => {
    setSearch(e.target.value)
    e.target.value.length > 2 && setSuggestionVisible(true)
  }

  const handleSubmit = () => {
    navigate('/brands', { state: search });
  }

  return (
    <div className="bcontainer">
      <div className='img_wrapper'>
        <img width="70" src={logo} alt='logo' />
        <h4 className='title'>e-Detailer</h4>
      </div>
      <div className='search_wrapper'>
        <input type="text" value={search} onChange={(e) => handleChange(e)} className="searchField" placeholder='search' />
        {suggestionsVisible && 
        <ul className='suggestion'>
        {
          suggestions.length > 0 && suggestions.map((value, i) => (
              <li key={i} onClick={() => handleClick(value)} className='suggest-child'>{value}</li>
          ))
        }
        </ul>
        }
        <div className='btn_wrapper'>
          <p className='text'>Search by store code</p>
          <button type='button' className='submitBtn' onClick={handleSubmit}>Submit</button>
        </div>
      </div>
    </div>
  )
}

export default Search