import React from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../images/logo.png'
import './common.css';

function NavBar({title}) {
    
    const navigate = useNavigate();

  return (
    <div className='navbarhead'>
        <img width={40} src={logo} alt="brand" />
        <p className='head_title'>{title}</p>
        <a className='link-back'>
            <button className='btn-back' type='button' onClick={()=>navigate(-1)} > Back </button>
        </a>
    </div>
  )
}

export default NavBar