import React, { useEffect, useState } from 'react'
import NavBar from '../Common/NavBar'
import Accordion from 'react-bootstrap/Accordion';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

function RetailerData() {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [toggle, setToggle] = useState(false);

  const navigate = useNavigate();

  const { state } = useLocation();;

  const { brand_name, retailer_code } = state

  useEffect(() => {
    const auth = {
      username: 'Vedant',
      password: 'Vedant123'
    }
     const getBrands = async() => {
      setLoading(true)
      try{
        axios.post(`http://13.232.14.208/api/searchq`, { brand_name, retailer_code }, {auth})
          .then(function (response) {
            // handle success
            setData(response.data)
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          })
      }
      catch(err)
      {
        console.log(err.message)
      }
      finally{
        setLoading(false)
      }
    }

      brand_name && retailer_code &&  getBrands()
  }, [brand_name, retailer_code])
  return (
    <>
        <NavBar title={retailer_code}/>
        {/* <button onClick={() => {console.log(toggle); setToggle(!toggle)}} style={{width: 90, margin: '20px 10px'}} 
        className='btn-back' type='button' >
           Toggle All </button> */}
           <p style={{margin: '50px 10px'}}></p>
        {
        data.length > 0 && data.map((value, i) => (
            <Accordion key={i} defaultActiveKey={toggle ? i : -1}>
              <Accordion.Item eventKey={i}>
                  <Accordion.Header>{value.Short_Description}</Accordion.Header>
                  <Accordion.Body>
                  {value['SBFs/BFs/Brand']}
                  </Accordion.Body>
              </Accordion.Item>
            </Accordion>
        ))
        }
    </>
  )
}

export default RetailerData