import React from 'react'

function Card({title, handleBrandClick}) {

  return (
    <div className='card_wrapper'>
        <a alt="" onClick={handleBrandClick}>{title}</a>
    </div>
  )
}

export default Card